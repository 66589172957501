import React from "react"
import Helmet from "react-helmet"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/terms.css"

import TermsAndConditionBanner from "../images/terms-and-conditions.svg"

const TermsPage = () => (
  <Layout>
    <SEO title="Terms and condition us" />
    <section className="section-intro">
      <div className="section--content">
        <div className="home--left--section">
          <h1 className="home--banner-text">Our Terms</h1>
          <h4 className="home--banner-text-small">
            Please be sure to understand our terms and policy These terms and
            conditions apply to your use of the MUVIT mobile application and
            other related online and offline platforms ("MUVIT"), as well as to
            all information, recommendations and/or Services (defined below)
            provided to you by means of your use of MUVIT.
          </h4>
        </div>
        <div className="home--right--section">
          <div
            className="home-delivery--illustration"
            style={{ height: "220px" }}
          >
            <img src={TermsAndConditionBanner} alt="" />
          </div>
        </div>
      </div>
    </section>
    <section className="terms-and-condition">
      <h2 className="section--content--header center">Terms and condition</h2>
      <div className="section--content flex-section">
        <h3 className="terms-sub-header">
          MUVIT is powered and provided by Muvit Online Limited, a Nigerian
          Limited Liability Company ("we", "us", "our" or the "Company").
        </h3>
        <p>Please review these terms and conditions carefully. </p>
        <p>
          By using MUVIT or the Services, you acknowledge and agree that these
          terms and conditions and our privacy policy are binding on you. For
          more information on how we will use your personal data, please see our
          privacy policy. If you do not agree with these terms and conditions
          and our privacy policy, do not install the MUVIT mobile application,
          delete it and do not use MUVIT or the Services.
        </p>

        <div className="terms-list">
          <div className="term--item">
            <h3 className="term--item--header">
              1. Definitions and Interpretation
            </h3>
            <p>
              (a) In these terms and conditions, the following definitions and
              rules of interpretation apply unless otherwise defined or the
              context requires otherwise: "Advertisement" means any promotion
              messages and information that may appear on your mobile device
              during the operation of the App;
              <hr />
              "Nigeria" means The Federal Republic of Nigeria;
              <hr />
              "Partners" means the drivers or vehicle operators whose transport
              and logistics services are offered and may be requested through
              the use of MUVIT;
              <hr />
              "Services" means any and all services provided by us to you by
              means of your use of MUVIT, which include the services described
              in clause 3(a);
              <hr />
              "App" means the "MUVIT" mobile application supplied by us and
              downloaded and installed by you on your mobile device through
              which you may obtain the Services; and
              <hr />
              "Terms" means these terms and conditions, which may be amended and
              supplemented by us from time to time in accordance with clause 10.
              <hr />
              <hr />
              (b) Headings are for convenience only and do not affect
              interpretation. The singular includes the plural and the masculine
              shall include the feminine and neuter and vice versa.
              <hr />
              <hr />
              (c) A reference to a person, Limited liability Company, trust,
              partnership, unincorporated body or other entity includes any of
              them.
            </p>
          </div>
          <div className="term--item">
            <h3 className="term--item--header">2. License of App</h3>
            <p>
              (a) Subject to your compliance with these Terms, we grant you a
              limited non-exclusive, non-transferable licence to download and
              install one copy of the App on your mobile device and to run such
              copy of the App solely for your own personal use.
              <hr />
              <hr />
              (b) Your use of MUVIT grants you no rights in relation to our
              intellectual property rights (including, without limitation,
              copyright, trademarks, logos, graphics, photographs, animations,
              videos and text or rights in and to the App and applications) or
              the intellectual property of our retail or advertising <hr />
              partners, other than the non-transferable, personal right to use
              and receive the Services in accordance with these Terms.
              <hr />
              <hr />
              (c) You must not <hr />
              (i) License, sublicense, sell, resell, transfer, assign,
              distribute or otherwise commercially exploit or make available to
              any third party the Services or the App in any way; <hr />
              (ii) Modify or make derivative works based upon the Services or
              App; <hr />
              (iii) Create Internet “links” to the Services or “frame” or
              “mirror” any App on any other server or wireless or Internet-based
              device; (iv) Reverse engineer or access the App in order to <hr />
              (1) Design or build a competitive product or service, <hr />
              (2) Design or build a product using similar ideas, features,
              functions or graphics of the Services or App, or <hr />
              (3) Copy any ideas, features, functions or graphics of the
              Services or App, or <hr /> <hr />
              (v) Launch an automated program or script, including, but not
              limited to, web spiders, web crawlers, web robots, web ants, web
              indexers, bots, viruses or worms, or any program which may make
              multiple server requests per second, or unduly burdens or hinders
              the operation and/or performance of the Services or App.
            </p>
          </div>
          <div className="term--item">
            <h3 className="term--item--header">3. Services</h3>
            <p>
              (a) We offer a technology platform to provide information and a
              means for you to obtain transport and logistics services from
              Participating Providers through the use of MUVIT. MUVIT allows you
              to send a request for transport and logistics services (together
              with details of you and your journey) to Participating Providers
              and each relevant Participating Provider has the sole discretion
              to accept or reject each such request. If a Participating Provider
              accepts a request, MUVIT will notify you and provide information
              about the Participating Provider such as the vehicle licence
              number, contact number and driver rating (if any). <hr />
              <hr />
              (b) You acknowledge and agree that we only act as an intermediary
              between you and the Participating Providers and we are not your
              agent or the agent of the Participating Providers. We are not a
              transportation carrier and do not provide transportation services.
              We are not responsible for the behaviour, negligence, conduct,
              actions or inactions on the part of the Participating Providers
              that you may use (through MUVIT or otherwise). Any contract for
              the provision of transport and logistics services is between you
              and the Participating Providers and not us. We shall never be a
              party to any agreement (to be) entered into between you and any
              Participating Providers. If you have any complaint in relation to
              the transport and logistics service provided then that dispute
              must be taken up with the Participating Provider directly.
              <hr />
              <hr />
              (c) You must keep secure and confidential and not disclose to any
              third party any username or password that we may provide to you in
              relation to access to MUVIT and the Services.
              <hr />
              <hr />
              (d) We shall assume that any person using your mobile device, your
              username and password is you or a person authorized by you.
            </p>
          </div>
          <div className="term--item">
            <h3 className="term--item--header">
              4. Your use of MUVIT and the Services
            </h3>
            <p>
              You agree:
              <hr />
              <hr />
              (a) And warrant that all information and details provided by you
              to us (including through the App) are true, accurate, complete and
              up-to-date in all respects and at all times;
              <hr />
              <hr />
              (b) you will not use MUVIT or the Services: for any unlawful
              purpose; in any way that interrupts, damages, impairs or renders
              MUVIT or the Services less efficient; to access or attempt to
              access the accounts of other users or to penetrate or attempt to
              penetrate any security measures; to advertise or promote third
              party or your own products or services; (c) To comply with all
              applicable laws while using MUVIT or the Services;
              <hr />
              <hr />
              (d) You will not use the MUVIT or the Services to cause nuisance,
              annoyance or inconvenience;
              <hr />
              <hr />
              (e) To refrain from doing anything which we reasonably believe to
              be disreputable or capable of damaging our reputation;
              <hr />
              <hr />
              (f) To provide us with whatever proof of identity we may
              reasonably request;
              <hr />
              <hr />
              (g) to treat Participating Providers introduced to you through
              MUVIT with respect and not to cause damage to their vehicles or
              engage in any unlawful, threatening, harassing, abusive behaviour
              or activity whilst using their vehicles or the Services; and
              <hr />
              <hr />
              (h) To compensate and defend we fully against any claims or legal
              proceedings brought against us by any other person as a result of
              your breach of these Terms.
              <hr />
              <hr />
              We reserve the right to suspend, restrict or terminate your access
              to MUVIT and the Services at any time without advance notice or
              liability if we have reasonable grounds to believe you have
              breached any of these Terms or in our opinion you misuse MUVIT or
              the Services. This shall not limit our right to take any other
              action against you that we consider appropriate to defend our
              rights or those of any other person.
            </p>
          </div>
          <div className="term--item">
            <h3 className="term--item--header">5. Charges and Payment</h3>
            <p>
              It is free to generally use the App. For details about the cost of
              the transport and logistics services provided by the Participating
              Providers, please refer to the latest pricing guidelines posted on
              www.muvit.com.ng and/or the App, which may be amended and updated
              at our sole discretion from time to time without prior notice. You
              agree to make payment in full directly to any Participating
              Provider introduced to you through MUVIT for any services provided
              by such Participating Provider to you.
            </p>
          </div>
          <div className="term--item">
            <h3 className="term--item--header">6. Promotions</h3>
            <p>
              We may from time to time offer promotions on MUVIT. We reserve the
              right to amend, suspend, withdraw or terminate, whether in whole
              or part, any and all such promotions without prior notice and at
              our absolute discretion.
            </p>
          </div>
          <div className="term--item">
            <h3 className="term--item--header">7. Links and Advertisements</h3>
            <p>
              MUVIT may contain Advertisements and links to third party
              websites. We shall not be responsible for the contents and
              accuracy of any Advertisement. Any links to third party websites
              are provided solely for your convenience and not in any way as an
              endorsement by us of the contents on such third party websites. If
              you access any linked third party website or Advertisement, you do
              so entirely at your own risk. We shall not be responsible for any
              transactions between you and any advertisers or third party
              website operators.
            </p>
          </div>
          <div className="term--item">
            <h3 className="term--item--header">8. Indemnification</h3>
            <p>
              By accepting these Terms and using MUVIT or the Services, you
              agree that you shall defend, indemnify and hold us, our
              affiliates, our licensors, and each of our and their respective
              officers, directors, other users, employees, attorneys and agents
              harmless from and against any and all claims, costs, damages,
              losses, liabilities and expenses (including attorneys' fees and
              costs) arising out of or in connection with: <hr />
              <hr />
              (a) Your violation or breach of any term of these Terms or any
              applicable law or regulation, whether or not referenced herein;{" "}
              <hr />
              <hr />
              (b) Your violation of any rights of any third party, including
              Participating Providers arranged via MUVIT, or <hr />
              <hr />
              (c) Your use or misuse of MUVIT or the Services.
            </p>
          </div>
          <div className="term--item">
            <h3 className="term--item--header">9. Disclaimers</h3>
            <p>
              (a) The App is provided to you on an “as is” basis. We do not
              guarantee that the App is compatible with your mobile device or
              that (the contents of) the App and our website are free of errors,
              defects, malware and viruses or that the App and our website are
              correct, up to date and accurate.
              <hr />
              <hr />
              (b) We do not warrant and accept no liability in connection with
              the availability of the Services, the availability of transport
              and logistics services from the Participating Providers through
              the use of the Services, the accuracy of the information or data
              provided as part of the Services or the quality, suitability and
              timeliness of the services of the Participating Providers.
              <hr />
              <hr />
              (c) Any quoted pick-up or journey times are best estimates only
              and we shall have no liability if a pick-up or journey time
              exceeds any estimate given or otherwise exceeds your expectations
              for whatever reason nor shall we have any other liability to you
              in connection with the time at which you and/or the goods to be
              transported by the Participating Providers reach or fail to reach
              the destination.
              <hr />
              <hr />
              (d) Vehicles of Participating Providers registered with MUVIT may
              be installed with GPS tracking device and/or other location
              tracking software or technology. Such vehicles are tracked using
              GPS or other location tracking technology to facilitate and
              improve route planning only. You acknowledge and accept that the
              location tracking feature is not error-free and the information
              provided by it may not be accurate. Your use of the location
              tracking feature is solely at your own risk and we accept no
              responsibility or liability in connection with any such use of
              and/or reliance on such location tracking information by you.
              <hr />
              <hr />
              (e) It shall be your sole responsibility to ensure that valuable,
              unusual or any other items are covered by appropriate insurance.
              In case of lost items inside the vehicles of Participating
              Providers during the journey, we will use reasonable endeavours to
              liaise with the relevant Participating Provider to locate such
              items but in no event shall we be responsible or liable for the
              loss of, or damage to, any such items.
              <hr />
              <hr />
              (f) The use of MUVIT and the Services is at your sole risk. To the
              fullest extent permitted by applicable law, we shall not be liable
              for any damages resulting from or in connection with the use or
              inability to use MUVIT or the Services (including any
              consequential, indirect, incidental damages or any loss of profit
              or damages to your mobile device). Without prejudice to the
              foregoing and insofar as permitted under applicable law, our
              aggregate liability shall in no event exceed an amount of N5000.
            </p>
          </div>
          <div className="term--item">
            <h3 className="term--item--header">
              10. Modification of Terms and the Services
            </h3>
            <p>
              We may, at our sole discretion, from time to time amend or
              otherwise modify or replace any of these Terms, or change, suspend
              or discontinue MUVIT or the Services (including but not limited to
              the availability of any feature or content) by sending you notice
              through MUVIT or by posting a notice on our website at
              www.muvit.com.ng, which shall be effective and binding on you upon
              notice or posting. We may also impose limits on certain features
              and services or restrict your access to parts or all of the
              Services without notice or liability.
            </p>
          </div>
          <div className="term--item">
            <h3 className="term--item--header">
              11. Personal data and privacy
            </h3>
            <p>
              The collection, use, storage and transfer of your personal data
              are generally subject to our privacy policy and personal
              information collection statement, the latest version of which is
              at www.muvit.com.ng. Our privacy policy, as may be amended and
              updated from time to time at our sole discretion, is incorporated
              by reference into these Terms.
            </p>
          </div>
          <div className="term--item">
            <h3 className="term--item--header">12. Miscellaneous</h3>
            <p>
              (a) You may not assign or transfer any of your rights or
              obligations under these Terms to any person without our prior
              written approval. We may assign and transfer any of our rights and
              obligations under these Terms to any person.
              <hr />
              <hr />
              (b) We may give notice by means of a general notice on MUVIT, or
              by electronic mail to your email address on record in our account
              information, or by written communication sent by regular mail to
              your address on record in our account information.
              <hr />
              <hr />
              (c) If any provision under these Terms is rendered void, illegal
              or unenforceable in any respect under any applicable law, the
              validity, legality and enforceability of the remaining provisions
              shall not in any way be affected by it and shall continue to
              apply.
              <hr />
              <hr />
              (d) The original text of these Terms is in English. In the event
              of any inconsistency between the English text and Nigerian
              Languages, the English text shall prevail.
            </p>
          </div>
          <div className="term--item">
            <h3 className="term--item--header">
              13. Governing law and jurisdiction
            </h3>
            <p>
              These Terms shall be governed by and construed in accordance with
              the laws of Nigeria. Both parties agree to submit to the exclusive
              jurisdiction of the courts of Nigeria as regards any dispute or
              matter arising under these Terms.
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)
export default TermsPage
